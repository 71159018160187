<template>
  <div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="货道状态：">
                <span>{{ props.row.passInfo }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
            label="设备id"
            prop="deviceId"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <!--<el-table-column
            label="设备imei"
            align="center"
            header-align="center"
            prop="deviceSn"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备类型"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{ row.deviceType === 0 ? '有屏租赁柜' : row.deviceType === 1 ? '轮椅' : '-' }}
          </template>
        </el-table-column>-->
        <el-table-column
            label="上报时间"
            align="center"
            header-align="center"
            prop="reportDate"
            width="180"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ dateStr(row.reportDate) }}
          </template>
        </el-table-column>
        <!--<el-table-column
            label="设备状态"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{ row.status === 0 ? '异常' : row.status === 1 ? '正常' : '-' }}
          </template>
        </el-table-column>-->
        <el-table-column
            label="固件版本号"
            align="center"
            header-align="center"
            prop="firmVer"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <!--<el-table-column
            label="apkToken"
            align="center"
            header-align="center"
            prop="apkToken"
            :formatter="commonFormatter"
        >
        </el-table-column>-->
        <el-table-column
            label="运营商"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{ row.operatorType === '0' ? '其他' : row.operatorType === '1' ? '移动' : row.operatorType === '2' ? '联通' : row.operatorType === '3' ? '电信' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="电量"
            align="center"
            header-align="center"
            prop="electricity"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="信号质量"
            align="center"
            header-align="center"
            prop="signalCsq"
            :formatter="commonFormatter"
        >
        </el-table-column>

        <el-table-column
                label="iccid"
                align="center"
                header-align="center"
                prop="iccid"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="内存使用率"
                align="center"
                header-align="center"
                prop="usageMen"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="cpu使用率"
                align="center"
                header-align="center"
                prop="usageCpu"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="存储使用率"
                align="center"
                header-align="center"
                prop="usageStorage"
                :formatter="commonFormatter"
        >
        </el-table-column>

      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
export default {
  name: "HeartRecordChair",
  mixins:[tableUse],
  computed:{
    deviceId(){
      return this.$route.query.id
    }
  },
  data() {
    return {
      tableData: [
      ],
    }
  },
  methods:{
    dateStr(dateStr) {
      var pattern = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
      //将时间格式化成 yyyy-MM-dd HH:mm:ss
      var formatDateStr = dateStr.replace(pattern, '$1-$2-$3 $4:$5:$6');
      return formatDateStr;
    },
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        id:this.deviceId
      }
      this.$axios.post("/sys-api/device-api/heartRecordChair",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">
.table-container{
  height: calc(100% - 50px - 20px);
  padding-top: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
  padding-left: 48px;
}
.demo-table-expand ::v-deep.el-form-item__label {
  width: 90px ;
}
</style>
